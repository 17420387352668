<script>
import Vertical from "./vertical";

export default {
  page: {
    title: "Adaremit Business",
    meta: [{ title: "Adaremit | Kirim uang ke luar negeri dengan Aman, Mudah & Murah" },
    {content:"Adaremit bertujuan untuk mempermudah pengiriman uang ke luar negeri dengan mudah, aman dan nyaman dengan izin No. 12/68/MDN/7 oleh Bank Indonesia"}]
  },
  components: { Vertical },
  data() {
    return {};
  },
  methods: {}
};
</script>

<template>
  <div>
    <vertical>
      <slot />
    </vertical>
  </div>
</template>
