export const menuItems = [
    {
        label: "menu",
        isTitle: true
    },
    {
        label: 'Dashboard',
        icon: 'ri-apps-fill',
        // badge: {
        //     variant: "success",
        //     text: "10"
        // },
        link: '/'
    },
    {
        label: 'Send Money',
        icon: 'ri-send-plane-fill',
        link: '/send',
    },
    
    {
        label: 'Senders',
        icon: 'ri-user-add-line',
        link: '/sender'
    },
    {
        label: 'Recipients',
        icon: 'ri-user-add-fill',
        link: '/recipient'
    },
    {
        label: 'Transactions',
        icon: 'ri-file-text-fill',
        link: '/transactions'
    },
    {
        label: 'Batch',
        icon: 'ri-upload-2-fill',
        link: '/batch'
    },
    // {
    //     label: 'Upload',
    //     icon: 'ri-upload-2-fill',
    //     subItems:[
    //         {
    //             label: 'Upload',
    //             link: '/upload',
    //         },
    //         {
    //             label: 'Status',
    //             link: '/batch',
    //         }
    //     ]
    // },
    // {
    //     label: 'Invoice',
    //     icon: 'ri-clipboard-fill',
    //     link: '/invoice'
    // },
    {
        label: "Account",
        isTitle: true
    },
    {
        label: 'Balance',
        icon: 'ri-wallet-3-fill',
        link: '/balance',
    },
    {
        label: 'Profile',
        icon: 'ri-profile-line',
        link: '/profile'
    },
    {
        label: 'Notification',
        icon: 'ri-notification-3-fill',
        link: '/notification'
    },
    // {
    //     label: 'Accounts',
    //     icon: 'mdi mdi-account-cog',
    //     link: '/accounts'
    // },
    // {
    //     label: 'Contact Us',
    //     icon: 'ri-file-text-fill',
    //     link: '/contact-us'
    // },
    
    // {
    //     id:9,
    //     label: 'Multi Level',
    //     icon: 'ri-share-line',
    //     subItems:[
    //         {
    //             id:10,
    //             label: 'Level 1.1',
    //             link: '',
    //         },
    //         {
    //             id:11,
    //             label: 'Level 1.2',
    //             link: '',
    //             subItems:[
    //                 {
    //                     id:11,
    //                     label: 'Level 2.1',
    //                     link: '',
    //                 },
    //                 {
    //                     id:11,
    //                     label: 'Level 2.2',
    //                     link: '',
    //                 }
    //             ]
    //         }
    //     ]
    // },
]